import React from 'react';
import NumberFormat from 'react-number-format';
import {
    TextField,
} from '@mui/material';

export const NumberFormatCurrency = React.forwardRef(function NumberFormatCurrency(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            thousandsGroupStyle='lakh'
            isNumericString
            prefix=""
        />
    );
});

const NumberFormatPercent = React.forwardRef(function NumberFormatPercent(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            prefix=""
            suffix="%"
        />
    );
});

export const CurrencyInput = (props) => {
    const { id, label, formik, placeholder } = props;
    return <TextField
        fullWidth
        id={id}
        name={id}
        label={label}
        value={formik.values[id]}
        onChange={formik.handleChange}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        type="text"
        variant="standard"
        placeholder={placeholder}
        InputProps={{
            inputComponent: NumberFormatCurrency,
        }}
        margin="normal"
        helperText={formik.touched[id] && formik.errors[id]}
    />
}

export const PercentInput = (props) => {
    const { id, label, formik, placeholder } = props;
    return <TextField
        fullWidth
        id={id}
        name={id}
        label={label}
        value={formik.values[id]}
        onChange={formik.handleChange}
        error={formik.touched[id] && Boolean(formik.errors[id])}
        type="text"
        variant="standard"
        placeholder={placeholder}
        InputProps={{
            inputComponent: NumberFormatPercent,
        }}
        margin="normal"
        helperText={formik.touched[id] && formik.errors[id]}
    />
}