import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Box, Card, CardContent, Typography } from "@mui/material";
import { CurrencyInput, PercentInput } from "./CustomInput";

const validationSchema = yup.object({
  expense: yup.number().min(0).max(999999999).required("Expense is required"),
  inflation: yup
    .number()
    .min(-1000)
    .max(+1000)
    .required("Inflation Rate is required"),
  income: yup
    .number()
    .min(0)
    .max(999999999)
    .required("Annual income is required"),
  lifespan: yup.number().when("age", (age) => {
    return yup
      .number()
      .min(age || 0, "Lifespan must be greater than " + (age ? "age" : "0"))
      .max(140)
      .required("Lifespan is required");
  }),
  hike: yup
    .number()
    .min(-1000)
    .max(+1000)
    .required("Annual hike is required"),
  networth: yup
    .number()
    .min(-99999999999999)
    .max(99999999999999)
    .required("Current networth is required"),
  returns: yup
    .number()
    .min(-1000)
    .max(+1000)
    .required("Expected returns is required"),
  age: yup.number().min(0).max(140).required("Current Age is required"),
});

const InputForm = (props) => {
  const { onSubmit, initialValues } = props;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          <Card sx={{ margin: 1, minWidth: 350 }}>
            <CardContent>
              <Typography variant="h6">Personal data</Typography>
              <CurrencyInput
                id="age"
                label="Age"
                formik={formik}
                placeholder="25"
              />
              <CurrencyInput
                id="lifespan"
                label="Expected Lifespan"
                formik={formik}
                placeholder="85"
              />
            </CardContent>
          </Card>
          <Card sx={{ margin: 1, minWidth: 400 }}>
            <CardContent>
              <Typography variant="h6">Assets</Typography>
              <CurrencyInput
                id="networth"
                label="Current Networth"
                formik={formik}
                placeholder="1,00,000"
              />
              <PercentInput
                id="returns"
                label="Expected Returns (%)"
                formik={formik}
                placeholder="8%"
              />
            </CardContent>
          </Card>

          <Card sx={{ margin: 1, minWidth: 400 }}>
            <CardContent>
              <Typography variant="h6">Income</Typography>
              <CurrencyInput
                id="income"
                label="Current Annual Income"
                formik={formik}
                placeholder="7,00,000"
              />
              {/* <FormikCurrencySlider
              id="income"
              label="Annual Income"
              formik={formik}
            /> */}
              <PercentInput
                id="hike"
                label="Yearly Income Hike (%)"
                formik={formik}
                placeholder="10%"
              />
            </CardContent>
          </Card>

          <Card sx={{ margin: 1, minWidth: 400 }}>
            <CardContent>
              <Typography variant="h6">Expense</Typography>
              <CurrencyInput
                id="expense"
                label="Monthly Expenses"
                formik={formik}
                placeholder="30,000"
              />

              <PercentInput
                id="inflation"
                label="Personal Inflation (%)"
                formik={formik}
                placeholder="7%"
              />
            </CardContent>
          </Card>
        </Box>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          style={{ marginTop: 10 }}
        >
          Calculate
        </Button>
      </Box>
    </>
  );
};

export default InputForm;
