import * as React from "react";
import { Typography, Container, CssBaseline, Link } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RetirementCalculator from "./components/RetirementCalculator";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FaqPage from "./components/FaqPage";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <Link color="inherit" href="mailto:feedback.fc@jasir.dev">
        Send Feedback
      </Link>
      {" | "}
      {"Copyright © "}
      <Link color="inherit" href="https://jasir.dev/">
        Jasir KT
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Routes>
            <Route path="/" element={<RetirementCalculator />} />
            <Route path="/faq" element={<FaqPage />} />
          </Routes>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
