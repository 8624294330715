import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FaqPage() {
  return (
    <Box
      sx={{
        marginTop: 8,
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            I want to leave something for my kids to inherit.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This is not directly supported feature, but as a workaround you can
            increment the retirement age. If you live less than the specified
            retirement age, whatever left can be inherited by your children.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            How to calculate my ideal spending based on my desired retirement
            age?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This is not directly supported feature, but as a workaround you can adjust your expense/income values until the result shows your desired retirement age.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
