import React from "react";
import { Box } from "@mui/material";
import InputForm from "../InputForm";
import Result from "../Result";
import { calculate } from "../../utils/calculator";
import { useSearchParams } from "react-router-dom";

function RetirementCalculator() {
  const [result, setResult] = React.useState({ submitted: false });

  const [searchParams, setSearchParams] = useSearchParams();

  const getUrlParamFloat = (param) => {
    var res = searchParams.get(param)
      ? Math.round(parseFloat(searchParams.get(param)) * 100) / 100
      : "";
    return isNaN(res) ? "" : res;
  };

  const getUrlParamInt = (param) => {
    var res = searchParams.get(param) ? parseInt(searchParams.get(param)) : "";
    return isNaN(res) ? "" : res;
  };

  const initialValues = {
    networth: getUrlParamFloat("networth"),
    returns: getUrlParamFloat("returns"),
    income: getUrlParamFloat("income"),
    expense: getUrlParamFloat("expense"),
    hike: getUrlParamFloat("hike"),
    inflation: getUrlParamFloat("inflation"),
    age: getUrlParamInt("age"),
    lifespan: getUrlParamInt("lifespan"),
  };

  const handleSubmit = (values) => {
    setResult(calculate(values));
    setSearchParams(values);
  };
  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <InputForm onSubmit={handleSubmit} initialValues={initialValues} />
      </Box>
      <Result result={result} />
    </>
  );
}

export default RetirementCalculator;
