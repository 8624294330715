import React from 'react';
import {
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Tooltip
} from '@mui/material';

const roundPrecision = (val, precision) => {
    var multiplier = Math.pow(10, precision);
    return Math.round(val * multiplier) / multiplier;
}

const shortForm = (val, precision, r = 0) => {
    if (r > 5) {
        return "∞";
    }
    if (val < 0) {
        return "-" + shortForm(val * -1, 2, r + 1);
    }
    if (val < 1000) {
        return roundPrecision(val, precision, r + 1);
    }
    if (val < 100000) {
        return roundPrecision(val / 1000, precision, r + 1) + "K";
    }
    if (val < 10000000) {
        return roundPrecision(val / 100000, precision, r + 1) + "L";
    }
    var result = shortForm(val / 10000000, 2, r + 1);
    if (result === "∞") {
        return "∞";
    }
    return result + "Cr";
}

const CurrencyCell = (props) => {
    var {val, color} = props;
    if (val < 0) color *= -1;
    var hex;
    if (color === 0) {
        hex = "#31299e";
    } else if (color === 1) {
        hex = "#07b839";
    } else {
        hex = "#d66233";
    }
    return <TableCell
        align="right"
        style={{ color: hex }}>
        {shortForm(val, 2)}
    </TableCell>
}

const confidence = data => {
    const percent = data.eoyNetWorth / data.retirementEstimate;
    if (percent < 0.6) {
        return "#e36b6b"; //No confidence
    } else if (percent < 1) {
        return "#e3c76b"; //Medium confidence
    } else {
        return "#6be3b3" //High confidence
    }
}

const ResultTable = (props) => {
    const { yearly } = props;

    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="finance results">
            <TableHead>
                <TableRow>
                    <TableCell align="right">Age</TableCell>
                    <TableCell align="right">BOY Networth</TableCell>
                    <TableCell align="right">(+)Income</TableCell>
                    <TableCell align="right">(-)Tax</TableCell>
                    <TableCell align="right">(-)Expense</TableCell>
                    <TableCell align="right">(+)Growth</TableCell>
                    <TableCell align="right">(=) EOY Networth</TableCell>
                    {/* <TableCell align="right">Networth needed</TableCell> */}
                    <TableCell align="right">% Confidence</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(yearly).map(([age, y]) => (
                    <TableRow
                        key={age}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" align="right">
                            {age}
                        </TableCell>
                        <CurrencyCell val={y.networth} color={0} />
                        <CurrencyCell val={y.income} color={1} />
                        <CurrencyCell val={y.taxAmount} color={-1} />
                        <CurrencyCell val={y.expense} color={-1} />
                        <CurrencyCell val={y.growth} color={1} />
                        <CurrencyCell val={y.eoyNetWorth} color={0} />
                        {/* <TableCell align="right">{shortForm(y.retirementEstimate, 2)}</TableCell> */}
                        <TableCell align="right" style={{ backgroundColor: confidence(y) }}>
                            <Tooltip title={y.meta.confidence}>
                            <span>{shortForm(y.confidence, 0)}%</span>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ResultTable;