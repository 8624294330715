import React from 'react';
import {
    Typography,
} from '@mui/material';
import ResultTable from './table';

const Result = (props) => {
    const { result } = props;
    const { yearly, estimatedRetirementAge } = result || {};

    return <>
        <Typography align="center" margin={2} style={{ color: "green", fontSize: 22, fontWeight: 'bold' }}>
            {estimatedRetirementAge > 0 ?
                "You will be financially free at " + estimatedRetirementAge :
                ""
            }
        </Typography>
        {yearly &&
            <ResultTable yearly={yearly} />
        }
    </>

}

export default Result;