export const calculate = (values) => {
    const expense = parseFloat(values.expense) * 12;
    const lifespan = parseInt(values.lifespan);
    const age = parseInt(values.age);
    const income = parseFloat(values.income);
    const networth = parseFloat(values.networth);
    const inflation = parseFloat(values.inflation) / 100;
    const hike = parseFloat(values.hike) / 100;
    const returns = parseFloat(values.returns) / 100;

    var yearly = {};
    yearly[age] = {
        networth,
        income,
        taxAmount: calculateTax(income),
        expense,
        growth: networth * returns
    };

    //if income is not sufficient to cover expenses, take from boy networth, hence less growth
    if(income - yearly[age].taxAmount - expense < 0) {
        yearly[age].growth = (networth + income - yearly[age].taxAmount - expense) * returns;
    }

    yearly[age].eoyNetWorth = calculateEoyNetWorth(yearly[age]);

    //Calculate expenses first
    for (var i = 1 + age; i <= lifespan; ++i) {
        var prev = yearly[(i - 1)];
        var cur = {};
        cur.expense = prev.expense * (1 + inflation);
        yearly[i] = cur;
    }

    //Calculate retirement estimate
    calculateRetirementEstimate(yearly, age, lifespan, returns);

    //Calculate other yearly values
    var estimatedRetirementAge = 199; //Nobody lives 199 years!
    for (i = 1 + age; i <= lifespan; ++i) {
        prev = yearly[(i - 1)];
        cur = yearly[i];
        cur.networth = prev.eoyNetWorth;
        cur.income = estimatedRetirementAge < i ? 0 : prev.income * (1 + hike);
        cur.taxAmount = calculateTax(cur.income);
        
        cur.growth = cur.networth * returns;
        //if income is not sufficient to cover expenses, take from boy networth, hence less growth
        if(cur.income - cur.taxAmount - cur.expense < 0) {
            cur.growth = (cur.networth + cur.income - cur.taxAmount - cur.expense) * returns;
        }

        cur.eoyNetWorth = calculateEoyNetWorth(cur);

        if (estimatedRetirementAge > i && cur.eoyNetWorth >= cur.retirementEstimate) {
            estimatedRetirementAge = i;
        }
    }

    calculateMeta(yearly);
    return { yearly, estimatedRetirementAge };
}


const calculateTax = income => {
    var tax = 0;
    tax += calculateBracket(income, 250001, 500000, 5);
    tax += calculateBracket(income, 500001, 750000, 10);
    tax += calculateBracket(income, 750001, 1000000, 15);
    tax += calculateBracket(income, 1000001, 1250000, 20);
    tax += calculateBracket(income, 1250001, 1500000, 25);
    tax += calculateBracket(income, 1500001, -1, 30);
    //TODO Add surcharge
    return tax;
}

const calculateEoyNetWorth = data => {
    return data.networth + data.income - data.taxAmount - data.expense + data.growth;
}

const calculateBracket = (income, min, max, percent) => {
    if (income < min) return 0;
    if (max >= 0 && income > max) income = max;
    return (income - (min - 1)) * percent / 100;
}

const calculateRetirementEstimate = (yearly, age, lifespan, returns) => {
    yearly[lifespan].retirementEstimate = 0;
    for (var i = lifespan - 1; i >= age; --i) {
        yearly[i].retirementEstimate = (yearly[i + 1].retirementEstimate / (1 + returns)) + yearly[i + 1].expense;
    }
}

const roundPrecision = (val, precision) => {
    var multiplier = Math.pow(10, precision);
    return Math.round(val * multiplier) / multiplier;
}

const shortForm = (val, precision, r = 0) => {
    if (r > 5) {
        return "∞";
    }
    if (val < 0) {
        return "-" + shortForm(val * -1, 2, r + 1);
    }
    if (val < 1000) {
        return roundPrecision(val, precision, r + 1);
    }
    if (val < 100000) {
        return roundPrecision(val / 1000, precision, r + 1) + "K";
    }
    if (val < 10000000) {
        return roundPrecision(val / 100000, precision, r + 1) + "L";
    }
    var result = shortForm(val / 10000000, 2, r + 1);
    if (result === "∞") {
        return "∞";
    }
    return result + "Cr";
}

const calculateMeta = (yearly) => {
    Object.entries(yearly).forEach(([age, y]) => {
        y.confidence = y.eoyNetWorth * 100 / y.retirementEstimate;
        y.meta = {};
        y.meta.confidence = "You need " + shortForm(y.retirementEstimate, 2) + " to be financially free at end of age " + age;
    });
}